import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ProductCard } from './ProductCard';
import { theme } from '../Theme';

export const priceStyle = `
  [data-flight-price] {
    display: flex;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -0.2rem;
  margin-left: -0.2rem;
  ${theme.above.md} {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .product-card {
    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
    .product-card-inner {
      margin: 0 0.2rem 0.5rem 0.2rem;
      ${theme.above.md} {
        margin: 0 1rem 2rem 1rem;
      }
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  children = null,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          >
            {children}
          </ProductCard>
        );
      })}
    </Wrapper>
  );
}

export default ProductGrid;
