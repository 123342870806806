import React from 'react';
import { styled } from 'linaria/react';

const NostoRowWrapper = styled('div')``;

const NostoRow = ({ ...props }) => {
  return <NostoRowWrapper />;
};

export default NostoRow;
