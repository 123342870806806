import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import ProductRow from './ProductRow/ProductRow';
import ParseJson from '../Util/ParseJson';
import { Link } from 'react-router-dom';
import { HallmarkBorder } from '../../components/ui/General';
import Image from '@jetshop/ui/Image/Image';
import { fullWidth } from '../ui/General';
import BlogOnPage from '../Blog/BlogOnPage';
import NostoRow from './NostoRow';
import MaxWidth from '../Layout/MaxWidth';
import { IconBar } from './IconBar';
import FindifyRecommendation from '../Findify/Recommendation';

const boxPadding = `
  padding: 1rem;
  ${theme.above.md} {
    padding: 2rem;
  }
`;

const ItemHTMLWrapper = styled('div')`
  position: relative;

  //HOVER EFFECT
  .item-image {
    position: relative;
    &:before {
      transition: opacity 0.2s;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0;
      z-index: 1;
    }
  }
  &:hover {
    .item-image {
      &:before {
        opacity: 0.1;
      }
    }
  }
`;

const ItemImage = styled('div')`
  display: block;
  overflow: hidden;
  .image-holder {
    background-size: cover;
    background-position: center;
  }
  img {
    //display: block;
    //width: 100%;
  }
`;

const ItemContent = styled('div')`
  display: block;
  text-align: center;
  overflow: hidden;
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1;
  }
  h1 {
    font-size: 4rem;
    ${theme.above.md} {
      font-size: 6rem;
    }
  }
  h2 {
    font-size: 3rem;
    ${theme.above.md} {
      font-size: 4rem;
    }
  }
  h3 {
    font-size: 2.4rem;
    ${theme.above.md} {
      font-size: 3rem;
    }
  }
  h4 {
    font-size: 1.6rem;
    ${theme.above.md} {
      font-size: 2rem;
    }
  }

  video {
    width: 100%;
  }

  //CUSTOM CLASSES TO USE IN ADMIN
  //OVERLAY CLASSES
  .overlay {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .overlay.center {
    width: 100%;
    ${boxPadding}
    &.top,
    &.bottom {
      -webkit-transform: translateX(-50%) translateY(0%);
      -ms-transform: translateX(-50%) translateY(0%);
      -o-transform: translateX(-50%) translateY(0%);
      transform: translateX(-50%) translateY(0%);
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
      top: initial;
    }
  }
  .overlay.light {
    > * {
      color: ${theme.colors.white};
    }
  }
  .overlay.left,
  .overlay.right {
    position: absolute;
    ${boxPadding}
    text-align: left;
    -webkit-transform: translateX(0%) translateY(-50%);
    -ms-transform: translateX(0%) translateY(-50%);
    -o-transform: translateX(0%) translateY(-50%);
    transform: translateX(0%) translateY(-50%);
    &.top,
    &.bottom {
      -webkit-transform: translateX(0%) translateY(0%);
      -ms-transform: translateX(0%) translateY(0%);
      -o-transform: translateX(0%) translateY(0%);
      transform: translateX(0%) translateY(0%);
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
      top: initial;
    }
  }
  .overlay.right {
    right: 0;
    left: 50%;
    text-align: right;
  }
  .overlay.left {
    left: 0;
  }
  .overlay.center {
    text-align: center;
  }

  .text-box {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 4rem 1rem;
    ${theme.above.lg} {
      padding: 1rem 3rem 3rem;
    }
    h3 {
      ${theme.above.lg} {
        //margin-bottom: 0;
      }
      &:after {
        margin: 30px auto 0;
        border-color: white;
      }
    }
    p {
      max-width: 800px;
      margin: 0 auto;
    }
    &.dark {
      background: ${theme.colors.greys[6]};
      > * {
        color: white;
      }
      h3:after {
        border-color: white;
      }
    }
  }

  .margin-top {
    margin-top: 3rem;
  }

  .grey-box {
    h2 {
      margin-bottom: 0.5rem;
      margin-top: 0.2rem;
    }
    text-align: center;
    padding: 50px 10px;
    background: ${theme.colors.vanillaWhite};
    ${fullWidth}
    > p {
      margin: 0 auto 15px;
      max-width: 700px;
    }
  }

  .center {
    text-align: center;
  }

  //SALE BUTTON
  .sale-button {
    background: ${theme.colors.buy};
    font-family: ${theme.font.families.heavy};
    font-weight: ${theme.font.weights.normal};
    display: inline-block;
    text-transform: uppercase;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    min-width: 260px;
    font-size: 1.8rem;
    transition: all 0.3s;
    &:hover {
      background: ${theme.colors.white};
      color: ${theme.colors.font};
    }
    &.secondary {
      background: ${theme.colors.white};
      color: ${theme.colors.font};
      &:hover {
        background: ${theme.colors.buy};
        color: ${theme.colors.white};
      }
    }
  }

  .title {
    ${HallmarkBorder};
    text-align: center;
    margin: 3% 0;
  }

  .margin {
    margin: 2% 0;
  }

  .seo-box {
    max-width: 900px;
    padding: 2% 0;
    h5 {
      text-transform: none;
    }
    h5 {
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }
`;

class ItemCustom extends Component {
  checkType() {
    let custom_item = { ...this.props.item };
    custom_item.type = 'custom';
    const json = ParseJson(custom_item.content);
    return json.isJson ? { ...custom_item, ...json.content } : custom_item;
  }
  getClass(item) {
    let className = 'item-custom-wrapper';
    if (item.image) className += ' image';
    return className;
  }
  getAspect(columns) {
    if (columns === 1) return '2:1';
    return '1:1';
  }
  renderHTMLItem(item) {
    const { columns } = this.props;

    //IMAGE SIZES
    let sizes = this.props.sizes || [1, 1, 1, 1];
    if (columns === 2) sizes = [1, 1, 1 / 2, 1 / 2];
    else if (columns === 3) sizes = [1, 1, 1 / 2, 1 / 3];
    else if (columns === 4) sizes = [1 / 2, 1 / 2, 1 / 4, 1 / 4];

    return (
      <ItemHTMLWrapper className={this.getClass(item)}>
        {item.image && (
          <ItemImage className="item-image">
            <Image
              className="image-holder"
              aspect={this.props.aspect || this.getAspect(columns)}
              sizes={sizes}
              src={item.image.url}
              alt={item.image.url}
              crop={this.props.crop || true}
              cover
            />
          </ItemImage>
        )}
        <ItemContent
          className="item-content"
          dangerouslySetInnerHTML={{
            __html: item.content,
          }}
        />
      </ItemHTMLWrapper>
    );
  }
  generateItemType(item) {
    let element = <Fragment />;
    switch (item.type) {
      case 'custom':
        element = (
          <React.Fragment>
            {item.linkUrl ? (
              <Link to={item.linkUrl}>{this.renderHTMLItem(item)}</Link>
            ) : (
              <Fragment>{this.renderHTMLItem(item)}</Fragment>
            )}
          </React.Fragment>
        );
        break;
      case 'list-category':
        element = (
          <MaxWidth>
            <div style={{ margin: '3rem 0' }}>
              <ProductRow {...item} />
            </div>
          </MaxWidth>
        );
        break;
      case 'list-findify':
        element = (
          <MaxWidth>
            <div style={{ margin: '3rem 0' }}>
              <FindifyRecommendation id={item.id} />
            </div>
          </MaxWidth>
        );
        break;
      case 'list-blog':
        element = <BlogOnPage {...item} />;
        break;
      case 'list-nosto':
        element = <NostoRow nostoId={item.id} />;
        break;
      case 'icon-bar':
        element = <IconBar item={item} />;
        break;
      default:
      // Do nothing
    }
    return <Fragment>{element}</Fragment>;
  }
  render() {
    const item = this.checkType();
    return this.generateItemType(item);
  }
}

export default ItemCustom;
