import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import BlogItem from './BlogItem';

export const itemList = {
  cols: {
    lg: 4,
    md: 3,
    sm: 2,
    xs: 2
  },
  space: {
    lg: 2,
    md: 2,
    sm: 2,
    xs: 3
  }
};

export const calcItemWidth = (columns, space) => {
  return (100 - space * columns) / columns + '%';
};

const ItemsWrapper = styled('div')`
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    ${theme.below.sm}{
      margin: 0 -${itemList.space.xs / 2 + '%'};
    }
    ${theme.only.sm}
      margin: 0 -${itemList.space.sm / 2 + '%'};
    }
    ${theme.only.md}
      margin: 0 -${itemList.space.md / 2 + '%'};
    }
    ${theme.only.lg}
      margin: 0 -${itemList.space.lg / 2 + '%'};
    }
    > li {
    }
    > li {
      ${theme.below.sm}{
        width: ${calcItemWidth(itemList.cols.xs, itemList.space.xs)};
        margin: 0 ${itemList.space.xs / 2 + '%'} ${itemList.space.xs + '%'};
      }
      ${theme.only.sm}
        width: ${calcItemWidth(itemList.cols.sm, itemList.space.sm)};
        margin: 0 ${itemList.space.sm / 2 + '%'} ${itemList.space.sm + '%'};
      }
      ${theme.only.md}
        width: ${calcItemWidth(itemList.cols.md, itemList.space.md)};
        margin: 0 ${itemList.space.md / 2 + '%'} ${itemList.space.md + '%'};
      }
      ${theme.only.lg}
        width: ${calcItemWidth(itemList.cols.lg, itemList.space.lg)};
        margin: 0 ${itemList.space.lg / 2 + '%'} ${itemList.space.lg + '%'};
      }
    }
  }
`;

const BlogItemList = ({ categories, blogPage }) => {
  if (categories && categories.length) {
    return (
      <ItemsWrapper className={blogPage && 'blog-page'}>
        <ul>
          {categories.map((category, index) => (
            <li key={category.id}>
              <BlogItem category={category} index={index} />
            </li>
          ))}
        </ul>
      </ItemsWrapper>
    );
  } else return null;
};

export default BlogItemList;
