import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-light-svg-icons';
import { faFish } from '@fortawesome/pro-light-svg-icons';
import { faShippingFast } from '@fortawesome/pro-light-svg-icons';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons';
import { faBoxHeart } from '@fortawesome/pro-light-svg-icons';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import MaxWidth from '../Layout/MaxWidth';

const IconBarWrapper = styled('div')`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding: 3rem 5px 1rem;
`;

const IconBarInner = styled('div')`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  h3 {
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
`;

const IconWrapper = styled('div')`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 0.7rem;
`;

function getIcon(index) {
  switch (index) {
    case 0:
      return faAward;
    case 1:
      return faFish;
    case 2:
      return faShippingFast;
    case 3:
      return faCreditCardFront;
    case 4:
      return faBoxHeart;
    default:
      return faAward;
  }
}

export const IconBar = ({ item }) => {
  const { list } = item;
  if (list.length)
    return (
      <IconBarWrapper>
        <MaxWidth>
          <IconBarInner>
            {list.map((listItem, index) => {
              return (
                <IconWrapper key={index}>
                  <StyledIcon icon={getIcon(index)} />
                  <h4>{listItem[0]}</h4>
                  <p>{listItem[1]}</p>
                </IconWrapper>
              );
            })}
          </IconBarInner>
        </MaxWidth>
      </IconBarWrapper>
    );
  else return null;
};
