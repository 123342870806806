import React, { Component } from 'react';
import { BlogListQuery } from './BlogListQuery.gql';
import { Query } from 'react-apollo';

//const categoryId = 198; // SET THE MAIN BLOG CATEGORY HERE
//const itemsLevel = 3; //SET HOW DEEP IN THE HIERACHY THE BLOGARTICLES IS

class BlogProvider extends Component {
  getCategoriesByLevel = (categories, level) => {
    return categories.filter(cat => cat.level === level);
  };
  getCategoriesUnderParent = (categories, parentID) => {
    return categories.filter(cat => cat.parentId === parentID);
  };

  getBlogItems = (categories, parentId, limit) => {
    let list = [];
    if (!parentId || parentId === this.props.categoryId) {
      list = this.getCategoriesByLevel(categories, this.props.itemsLevel);
    } else {
      list = this.getCategoriesUnderParent(categories, parentId);
    }

    let reversedList = list.reverse();

    if (limit > 0) reversedList = reversedList.slice(0, limit);

    return reversedList;
  };
  getCurrentCategory = (categories, currentPath) => {
    return categories.filter(cat => cat.primaryRoute.path === currentPath)[0];
  };
  render() {
    const { children, categoryId, itemsLevel } = this.props;
    return (
      <Query
        query={BlogListQuery}
        variables={{
          root: categoryId,
          levels: itemsLevel
        }}
      >
        {result => {
          const categories = (result.data && result.data.categories) || [];
          return children({
            categories: categories,
            getCategoriesByLevel: this.getCategoriesByLevel,
            getCategoriesUnderParent: this.getCategoriesUnderParent,
            getBlogItems: this.getBlogItems,
            getCurrentCategory: this.getCurrentCategory
          });
        }}
      </Query>
    );
  }
}

export default BlogProvider;
