import React from 'react';
import { styled } from 'linaria/react';
import Slider from 'react-slick';
import RightIcon from './icons/RightIcon';
import { theme } from '../Theme';

export const SliderStyles = styled('div')`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin: 0 -5px;
    ${theme.above.md} {
      padding: 0 -10px;
    }
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    padding: 0 5px;
    ${theme.above.md} {
      padding: 0 10px;
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  @charset 'UTF-8';
  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-next,
  .slick-prev {
    position: absolute;
    top: calc(50% - 16px);
    font-size: 2rem;
    line-height: 0;
    bottom: 5px;
    //top: calc(50% - 12px);
    width: 30px;
    height: 40px;
    cursor: pointer;
    color: #000;
    border: none;
    outline: 0;
    background: 0 0;
    z-index: 2;
    padding: 5px 0;
    background: rgba(255, 255, 255, 0.8);
    opacity: 0.6;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    opacity: 0.95;
  }
  .slick-next.slick-disabled,
  .slick-prev.slick-disabled {
    opacity: 0.25;
  }
  .slick-prev {
    transform: rotate(180deg);
    left: 0;
    //transform: rotate(90deg);
  }
  .slick-next {
    right: 0;
    //transform: rotate(270deg);
  }
  20px .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: 5px;
    left: 10px;
    width: auto;
    margin: 0;
    padding: 0 0;
    list-style: none;
    text-align: left;
    background: rgba(255, 255, 255, 0.5);
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 0;
    padding: 5px 5px;
    cursor: pointer;
  }
  .slick-dots li button {
    display: block;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
    font-size: 0;
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    display: inline-block;
    line-height: 5px;
    width: 5px;
    height: 5px;
    content: '';
    text-align: center;
    opacity: 0.25;
    background: #000;
    border-radius: 50%;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
  }
`;

const Next = props => {
  const { className, onClick } = props;
  return <RightIcon className={className} onClick={onClick} />;
};

const Prev = props => {
  const { className, onClick } = props;
  return <RightIcon className={className} onClick={onClick} />;
};

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Next />,
  prevArrow: <Prev />
};

const SlickSlider = ({ children, settings, className }) => {
  return (
    <SliderStyles>
      <Slider
        className={className}
        {...{
          ...sliderSettings,
          ...settings
        }}
      >
        {children}
      </Slider>
    </SliderStyles>
  );
};

export default SlickSlider;
