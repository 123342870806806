import React from 'react';
import BlogProvider from './BlogProvider';
import BlogItemList from './BlogItemList';
import SlickSlider from '../ui/SlickSlider';
import BlogItem from './BlogItem';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const BlogOnPageWrapper = styled('div')`
  ${theme.above.lg} {
    .slick-prev {
      //left: -20px;
    }
    .slick-next {
      //right: -20px;
    }
  }
`;

const BlogOnPage = ({ limit, slider, categoryId, menuLevel }) => {
  let itemsLevel = 2;
  if (menuLevel) itemsLevel = 3;
  return (
    <BlogProvider categoryId={categoryId} itemsLevel={itemsLevel}>
      {({ categories, getBlogItems }) => {
        if (categories.length > 0) {
          return (
            <BlogOnPageWrapper>
              {slider ? (
                <SlickSlider settings={sliderSettings}>
                  {getBlogItems(categories, false, limit).map(
                    (category, index) => (
                      <BlogItem category={category} key={index} index={index} />
                    )
                  )}
                </SlickSlider>
              ) : (
                <BlogItemList
                  categories={getBlogItems(categories, false, limit)}
                  blogPage={false}
                />
              )}
            </BlogOnPageWrapper>
          );
        } else return null;
      }}
    </BlogProvider>
  );
};

export default BlogOnPage;
