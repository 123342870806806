import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import ProductGrid from '../../CategoryPage/ProductGrid';
import productRowVariantsQuery from './ProductRowVariantsQuery.gql';
import productRowQuery from './ProductRowQuery.gql';
import ProductCard from '../../CategoryPage/ProductCard';
import SlickSlider from '../../ui/SlickSlider';
import t from '@jetshop/intl';
//import { ProductListHeader } from '../../ProductPage/StyledComponents';
import { Above } from '@jetshop/ui/Breakpoints';
import AddToCart from '../../Cart/AddToCart';
import Button from '../../ui/Button';

const backupId = 148;

const ProductRowWrapper = styled('div')`
  width: 100%;
`;

const BuyButton = styled(Button)`
  height: 36px;
  font-size: 1.1rem;
`;

//const ProductRowTitle = styled(ProductListHeader)``;

export const RowProducts = ({ category, slider, title, quickBuy, ...rest }) => {
  if (category && category.products && category.products.result.length < 4)
    slider = false;

  return (
    <ProductRowWrapper>
      {title && <h3>{t(title)}</h3>}
      {slider ? (
        <Above breakpoint="md">
          {matches => {
            let settings = {
              dots: false,
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 2
            };
            if (rest.sliderSettings) {
              settings = rest.sliderSettings;
            } else if (matches) {
              settings.slidesToShow = 4;
              settings.slidesToScroll = 4;
            }
            return (
              <SlickSlider settings={settings} className="product-row-slider">
                {category.products.result.map((product, index) => (
                  <ProductCard
                    key={category.id + '-' + product.id}
                    product={product}
                    categoryPath={null}
                  >
                    {quickBuy &&
                      product.stockStatus &&
                      product.stockStatus.buyable && (
                        <AddToCart
                          product={product}
                          quantity={1}
                          selectedVariation={product}
                        >
                          {add => (
                            <BuyButton
                              onClick={() => {
                                add();
                              }}
                            >
                              {t('Buy')}
                            </BuyButton>
                          )}
                        </AddToCart>
                      )}
                  </ProductCard>
                ))}
              </SlickSlider>
            );
          }}
        </Above>
      ) : (
        <ProductGrid
          products={category.products.result}
          listName={category.name}
          ProductComponent={ProductCard}
          categoryPath={null}
          {...rest}
        />
      )}
    </ProductRowWrapper>
  );
};

const ProductRow = ({
  id,
  limit = 12,
  orderBy = null,
  orderByDirection = 'DESCENDING',
  slider = false,
  title = '',
  quickBuy = false,
  ...rest
}) => {
  const query = quickBuy ? productRowVariantsQuery : productRowQuery;
  return (
    <Query
      query={query}
      variables={{
        id: parseInt(id, 10),
        first: parseInt(limit, 10),
        orderBy: orderBy,
        orderByDirection: orderByDirection
      }}
    >
      {result => {
        const category = result && result.data && result.data.category;
        if (category) {
          if (category.products && category.products.result.length > 0)
            return (
              <RowProducts
                category={category}
                slider={slider}
                title={title}
                quickBuy={quickBuy}
                {...rest}
              />
            );
          else if (id !== backupId) {
            return (
              <ProductRow
                id={backupId}
                limit={limit}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                slider={slider}
                title={title}
                {...rest}
              />
            );
          } else return null;
        } else return null;
      }}
    </Query>
  );
};

export default ProductRow;
