import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Item from './Item';
import HeroArea from './HeroArea';

//STYLE VARIABLES
export const startPage = {
  flexSpace: {
    lg: 0,
    md: 0,
    sm: 0
  },
  rowMargin: {
    lg: 0,
    md: 0,
    sm: 0
  }
};

const flexSpaceCalc = (space, amount) => {
  return (100 - space * (amount - 1)) / amount;
};

const StartPageRows = styled('div')`
  display: block;
`;

const Row = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  &.columns-1 {
    > li {
      width: 100%;
      .image-holder{
        ${theme.below.md}{
          padding-bottom: 100% !important;
        }
      }
    }
  }
  &.columns-2 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 2) + '%'};
    }
  }
  &.columns-3 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 3) + '%'};
    }
  }
  &.columns-4 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 4) + '%'};
    }
  }
  ${theme.below.sm}{
    &.columns-2,
    &.columns-3 {
      flex-direction: column;
      > li {
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  ${theme.below.md}{
    &.columns-4 {
      > li {
        width: ${flexSpaceCalc(startPage.flexSpace.sm, 2) + '%'};
      }
    }
  }

  //HERO AREA
  /*&.row-1 {
    display: block;
    overflow:hidden;
    > li {
      &:first-child{
        width: 66.6%;
        float: right;
        ${theme.below.md}{
          width: 100%;
          float: none;
          margin-bottom: 2%;
        }
      }
      &:nth-of-type(2), &:nth-of-type(3) {
        width: 32.8%;
        float: left;
        ${theme.below.md}{
          width: 49%;
          float: right;
        }        
      }
      &:nth-of-type(2){
        margin-bottom: 0.8%;
        ${theme.below.md}{
          width: 49%;
          float: left;
          margin: 0 0 0 0;
        }        
      }           
    }
  }*/

`;

class Render extends Component {
  getRows(items) {
    let rows = [];
    items.forEach(item => {
      const index = item.row - 1;
      if (!rows[index])
        rows[index] = {
          row: item.row,
          items: []
        };
      rows[index].items.push(item);
    });
    return rows;
  }
  render() {
    const { startPage } = this.props.result.data;
    if (startPage) {
      const rows = this.getRows(startPage.items);
      return (
        <StartPageRows>
          {rows.map((row, index) => {
            return (
              <Fragment key={index}>
                {index === 0 && row && row.items.length === 3 ? (
                  <HeroArea row={row} />
                ) : (
                  <Row
                    className={'columns-' + row.items.length + ' row-' + index}
                  >
                    {row.items.map((item, index) => (
                      <Item
                        item={item.item}
                        key={index}
                        columns={row.items.length}
                      />
                    ))}
                  </Row>
                )}
              </Fragment>
            );
          })}
        </StartPageRows>
      );
    } else return null;
  }
}

export default Render;
